import React, { useState, useEffect, useRef } from "react";
import ImageGallery from "react-image-gallery";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { Redirect } from 'react-router-dom';

import MetaTags from "../MetaTags/MetaTags";

import Connect from "../../store/config/connect";

import useIntersectionObserver from "../../utils/helpers/useIntersectionObserver";
import { urlImages } from "../../utils/helpers/general";
import { slugify } from "../../utils/helpers/general";

import { getPartnersLogo, getBanners } from "../../services/services";
import { getSuggestions } from "../../services/services";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";

import garantiaRegulamento from "../../docs/Regulamento_Garantia_Bravecto_-_2024.pdf";

import "./HomeDeslogada.css";
import "./image-gallery.css";

const videoIntersectionOptions = { thresold: 0.03125 }

const HomeDeslogada = (props) => {

  const history = useHistory();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const igRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  let newBtnStatus,
    keyNum = 0;
  const [btn, setBtnActive] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [partners, setPartners] = useState(false);
  const [banners, setBanners] = useState([]);
  const [allBanners, setAllBanners] = useState([]);
  const [loadingBanner, setLoadingBanner] = useState(true);
  const [loadingPartners, setLoadingPartners] = useState(true);

  const [isMobile, setIsMobile] = useState(props.helper.isMobile);

  var url = window.location.href;
// const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  useEffect(() => {
    setIsMobile(props.helper.isMobile);
  }, [props.helper.isMobile]);

  const [urlFiles] = useState(urlImages());

  useEffect(() => {
    // getPartnersLogo().then((res) => {
    //   if (res && res.status === 200) {
    //     console.log("resPartneres", res.data.map((item) => item.partnerName))


    //     
    //     setLoadingPartners(false);
    //   }
    // });
    let ctrl = 0,
      i = 0,
      partnersOrdered = [];

    const partners = ["cansei-de-ser-gato.webp", "seu-pet-art.webp", "pet-paluche.webp", "sephora.webp", "renner.webp", "evino.webp", "loccitane-au-bresil.webp", "philips.webp", "mobly.webp", "centauro.webp", "giuliana-flores.webp", "ponto-frio.webp", "canon.webp", "hoteis.webp", "sams-club.webp", "compra-certa.webp", "shop-club.webp", "loccitane-en-provence.webp", "under-armour.webp", "liv-up.webp", "drogaria-sao-paulo.webp", "north-face.webp", "tok-&-stok.webp", "vivara.webp", "fresh-4-pet.webp", "nespresso.webp", "zeedog.webp"]

    // partners.map((i) => {
    //   return (i.imageLogoPath = encodeURI(`${urlFiles}${i.imageLogoPath}`));
    // });

    // Desativando essa parte do codigo, pois estava criando um array vazio de parceiros.
    // partnersOrdered.push([]);

    let preArrayNumber = Math.ceil(partners.length / 9);
    // let preArrayNumber =
    //   Math.round(partners.length / 9) === 0
    //     ? 1
    //     : Math.round(partners.length / 9);
    for (let index = 0; index < preArrayNumber; index++) {
      partnersOrdered.push([]);
    }
    
    partners.forEach((partner, index) => {
      partnersOrdered[ctrl].push(partner);

      if ((index + 1) % 9 === 0 && index !== 0) {
      ctrl++;
      }
      // if (i % 9 === 0 && i !== 0) {
      //   ctrl++;
      //   partnersOrdered[ctrl].push(partners[i]);
      //   i++;
      // } else {
      //   partnersOrdered[ctrl].push(partners[i]);
      //   i++;
      // }
    });
    setPartners(partnersOrdered);

    // active: true
    // bannerName: "Garantia"
    // editDate: "2021-07-21T11:20:27.697"
    // id: "cdcbfb69-ca23-4178-b6b6-84d91040ece2"
    // imageBase64: "/9j/4Q31RXhpZgAATU0AKgAAAAgABwESAAMAAAABAAEAAAEaA
    // imageFileName: "Banner-Garantia (1).jpg"
    // imageMobileBase64: "iVBORw0KGgoAAAANSUhEUgAAAlgAAAGaCAIAAABczKqpAAAAC
    // imageMobileFileName: "Banner_Mob-Garantia-600x410.png"
    // index: 1
    // insertionDate: "2021-07-20T12:54:52.383"
    // notifications: []
    // remove: false

    // getBanners().then((res) => {
    //   if (res && res.status === 200) {
    //     console.log(res.data)
    const banners = ["banner-home-momentos-bravecto10anos.webp", "banner-campanha-vr-bravecto.webp", "banner-latam.webp", "garantia.webp"]
    // Banners antigos home deslogada
    // "finclass.webp", "pet-driver.webp", "staage.webp"

    setAllBanners(banners);
    setLoadingBanner(false);
    // }
    // });
    // getBanners().then((res) => {
    //   if (res && res.status === 200) {
    //     const banners = JSON.parse(res.request.response);
    //     setAllBanners(banners);
    //     setLoadingBanner(false);
    //   }
    // })
    // commit
  }, [urlFiles]);

  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    var params = props && props.match && props.match.params;
    var id = params && params.id;
    getSuggestions(id).then((res) => {
      if (res && res.status === 200) {
        var tipSuggestionsResponse = JSON.parse(res.request.response);
        setSuggestions(tipSuggestionsResponse);
      }
    });
  }, [props]);

  const [dicasSlider, setDicasSlider] = useState();
  useEffect(() => {
    setBanners([]);

    if (allBanners.length > 0) {
      let bannersState = [];
      allBanners.map((i) => {
        return bannersState.push({
          srcSet: `/content/assets/images/bannerHome/${isMobile ? `mobile-${i}` : `desk-${i}`}`,
        });
      });
      setBanners(bannersState);
    }

    setDicasSlider({
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: isMobile ? 1 : 3,
      slidesToScroll: isMobile ? 1 : 3,
    });
  }, [isMobile, allBanners, urlFiles]);

  const [parceirosSlider] = useState({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dotsClass: "HomeDeslogada--dots slick-dots",
  });
  const videoIframe = useRef()
  const [videoSrc, setVideoSrc] = useState('')
  const videoOnScreen = useIntersectionObserver(videoIframe, videoIntersectionOptions)
  useEffect(() => {
    if (videoOnScreen && !videoSrc) {
      setVideoSrc(`https://www.youtube.com/embed/q8wu-kbL6Ac`)
    }
  }, [videoOnScreen, videoSrc])

  function handleBtnActive(number) {
    newBtnStatus = {
      one: false,
      two: false,
      three: false,
    };
    newBtnStatus[number] = true;
    setBtnActive(newBtnStatus);
  }
  // const tokenSession = localStorage.getItem('bravecto-user');
  // if (tokenSession !== '' && tokenSession !== undefined && tokenSession !== null) {
  //   return <Redirect to="/clube-de-vantagens" />;
  // }

  return (
    <div id="home-deslogada">
      <div id="skip">
        <a href="#pagecontent">Pular para o conteúdo principal da página</a>
      </div>
      <h1 className="sr-only">
        Clube de Vantagens Bravecto: Aproveite Benefícios Ilimitados!
      </h1>
      <MetaTags
        title="Clube de Vantagens Bravecto: Aproveite Benefícios Ilimitados"
        description="Com o Clube de Vantagens Bravecto você tem Benefícios Ilimitados, Como até 70% em Cursos de Adestramento, 30% em Acessórios Pet e Muito Mais, Acesse!"
      />
      <Header />
      <div id="pagecontent" className="__container">
        <div id="carousel">
          <div id="app">
            {loadingBanner ? (
              <div id="preLoader">
                <Loader />
              </div>
            ) : (
              <ImageGallery
                ref={igRef}
                items={banners}
                showThumbnails={false}
                showBullets={true}
                showPlayButton={false}
                showNav={false}
                showFullscreenButton={false}
                autoPlay={true}
                slideInterval={4000}
                lazyLoad={true}
                onClick={(e) => {
                  if (
                    /\bgarantia*\b/i.test(
                      (allBanners[igRef.current.getCurrentIndex()].split('.'))[0]
                    )
                  ) {
                    history.push({ pathname: '/garantia-bravecto' });
                  }
                  else if (/\bbanner-cdv/i.test(
                    (allBanners[igRef.current.getCurrentIndex()].split('.'))[0]
                  )) {
                    window.open('https://www.bravecto.com.br/', '_blank');
                  }
                  else if (/\bbanner-campanha-vr-bravecto/i.test(
                    (allBanners[igRef.current.getCurrentIndex()].split('.'))[0]
                  )) {
                    history.push({ pathname: '/bravectoexperience' });
                  } else if (/\bbanner-home-momentos-bravecto10anos/i.test(
                    (allBanners[igRef.current.getCurrentIndex()].split('.'))[0]
                  )) {
                    history.push({ pathname: '/login' });
                  }
                }}
              />
            )}
          </div>
        </div>
        <div id="first-title"></div>
        <div id="scroll-down">
          <IoIosArrowDown onClick={executeScroll} />
        </div>
        <div id="comoFunciona">
          <span ref={myRef}></span>
          <h2 id="main-title">Como funciona</h2>
          <span id="comoFunction--desc">
            O Clube de Vantagens Bravecto foi criado para você, que sempre
            confiou no Bravecto para proteger seu pet. Agora queremos retribuir
            toda essa confiança! Ao cadastrar seu pet no Clube, você receberá
            uma série de benefícios e descontos. E, a cada compra de Bravecto
            que você fizer, você terá acesso à nossa exclusiva Garantia Bravecto{" "}
            <br />
            (veja{" "}
            <a
              id="diff"
              href={garantiaRegulamento}
              target="_blank"
              rel="noopener noreferrer"
            >
              regulamento
            </a>
            ).
          </span>
          <div id="video">
            <div id="embed">
              <iframe width="560" height="315" ref={videoIframe} src={videoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              {/*
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/xrPZDMmlMKY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Clube de Vantagens Bravecto">
                </iframe>
                */}
              {/* <ReactPlayer
                url={require("../../videos/bravecto.mp4")}
                controls
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      pip: "false",
                    },
                  },
                }}
              /> */}
            </div>
          </div>
          <Link to="/cadastrar" className="noDesktop">
            <button type="button" id="btn-cadastrar">
              Cadastre-se
            </button>
          </Link>
          <h2 id="light">
            <span> Para aproveitar, é muito simples:</span>
          </h2>
          <div id="info-carousel">
            <div
              id="buttons-carousel"
              style={{ display: isMobile ? "flex" : "none", }}
            >
              <button
                type="button"
                id={btn.one === true ? "button-active" : "button-disabled"}
                onClick={() => handleBtnActive("one")}
              >
                1
              </button>
              <div id="separator"></div>
              <button
                type="button"
                id={btn.two === true ? "button-active" : "button-disabled"}
                onClick={() => handleBtnActive("two")}
              >
                2
              </button>
              <div id="separator"></div>
              <button
                type="button"
                id={btn.three === true ? "button-active" : "button-disabled"}
                onClick={() => handleBtnActive("three")}
              >
                3
              </button>
            </div>
            <div id="carousel-container" style={{ marginTop: '60px' }}>
              {/* 1 */}
              <div
                className={`carousel-1 ${isMobile ? (btn.one === true ? "toggleIn" : "toggleOut") : ""
                  }`}
              >
                <div id="preImg">
                  <span className="img img-1"></span>
                </div>
                <h3 id="title">
                  Cadastre-se com
                  <br /> a sua nota fiscal
                </h3>
                <span id="info">
                  Faça seu cadastro com a nota fiscal e já <br /> tenha acesso a{" "}
                  <span id="info-bold">descontos exclusivos</span>
                </span>
              </div>
              {/* 2 */}
              <div
                className={`carousel-2 ${isMobile ? (btn.two === true ? "toggleIn" : "toggleOut") : ""
                  }`}
              >
                <div id="preImg">
                  <span className="img img-2"></span>
                </div>
                <h3 id="title">
                  Compre Bravecto e <br /> acumule estrelas
                </h3>
                <span id="info">
                  A cada compra dos nossos <br /> produtos você{" "}
                  <span id="info-bold">acumula estrelas</span>
                </span>
              </div>
              {/* 3 */}
              <div
                className={`carousel-3 ${isMobile
                  ? btn.three === true
                    ? "toggleIn"
                    : "toggleOut"
                  : ""
                  }`}
              >
                <div id="preImg">
                  <span className="img img-3"></span>
                </div>
                <h3 id="title">
                  Acesse a <br /> Garantia Bravecto
                </h3>
                <span id="info">
                  A cada NF de Bravecto cadastrada, <br />
                  você terá acesso à nossa{" "}
                  <span id="info-bold">
                    garantia <br />
                    exclusiva contra doenças
                  </span>{" "}
                  que
                  <br />
                  podem atingir seu pet!
                </span>
              </div>
            </div>
            <Link to="/cadastrar">
              <button type="button" id="btn-cadastrar">
                Cadastre-se
              </button>
            </Link>
          </div>
        </div>
        <div id="parceiros">
          <h2 id="main-title">Parceiros</h2>
          <div id="__container">
            {/* {loadingPartners ? (
              <div id="preLoader">
                <Loader />
              </div>
            ) : ( */}
            <Slider {...parceirosSlider}>
              {Object.keys(partners).map((i) => {
                return (
                  <div id="photoGrid" key={i}>
                    {partners[i].map((j) => {
                      keyNum++;
                      return (
                        <div id="photo" key={keyNum}>
                          <span
                            style={{
                              backgroundImage: `url("/content/assets/images/logosParceiros/${j}")`,
                            }}
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Slider>
            {/* )} */}
          </div>
        </div>
        <div id="dicas">
          <span id="main-title">Blog</span>
          <span id="info">
            Tudo o que você precisa saber sobre saúde, bem-estar, atividades
            pet, comportamento animal, conforto e muito mais, criados pela nossa
            equipe e pelo nosso time de parceiros.
          </span>
          <div id="image-gallery-dicas">
            <Slider {...dicasSlider}>
              {suggestions.map((i) => {
                return (
                  <div id="carousel" key={i}>
                    <Link to={`blog/${slugify(i.title)}`}>
                      <div id="carousel-container">
                        <div id="photo">
                          <span>
                            <img src={`${api}image/getImage/${i.id}/TipThumbnail`} alt="Blog" />
                          </span>
                        </div>
                        <div id="title">
                          <div id="little-photo">
                            <span>
                              <img src={`${api}image/getImage/${i.id}/TipLogo`} alt="Blog" />
                            </span>
                          </div>
                          <div id="text">
                            <span>{i.title}</span>
                          </div>
                        </div>
                        <div id="info-carousel">
                          <span
                            id="text"
                            dangerouslySetInnerHTML={{
                              __html: i.descriptionPreview,
                            }}
                          ></span>
                          <span id="leiaMais">
                            Leia mais &raquo;
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
          <span id="todasDicas">
            <Link to="/blog">Ver todos os conteúdos &raquo;</Link>
          </span>
        </div>
        <Footer />
      </div >
    </div >
  );
};

const mapStateToProps = ({ helper }) => {
  return {
    helper,
  };
};

export default Connect(mapStateToProps)(HomeDeslogada);

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import moment from "moment";

import Connect from "../../store/config/connect";

import Header from "../Header/Header";
import Beneficios from "../Beneficios/Beneficios";
import Footer from "../Footer/Footer";

import bannerDesk from "../../images/banner-home-momentos-bravecto.webp";
import bannerMobile from "../../images/banner-home-mobile-momentos-bravecto.webp";
// import MobileDescontoBravecto from './DescontoBravecto/Mobile';
// import DesktopDescontoBravecto from './DescontoBravecto/Desktop';
// import MobileDescontoBanhoTosa from './DescontoBanhoTosa/Mobile';
// import DesktopDescontoBanhoTosa from './DescontoBanhoTosa/Desktop';
// import Loader from '../Loader/Loader';
// import Pets from "../MeusPets/Pets";
import MeusPets from "../MeusPets/MeusPets";
import { getStars } from '../../services/services';

import "./ClubeDeVantagens.css";

const ClubeDeVantagens = (props) => {
  document.title = "Clube Bravecto | Meu Perfil";

  let expirationDate = moment(props.auth.user.nextExpiration).format(
    "DD/MM/YYYY"
  );

  // function handleSubmitPromotionalCode(){
  //     return true;
  // }

  const [isMobile, setIsMobile] = useState(props.helper.isMobile);
  useEffect(() => {
    setIsMobile(props.helper.isMobile);
  }, [props.helper.isMobile]);

  const [user, setUser] = useState(null);
  useEffect(() => {
    if (props.auth.user) {
      console.log(props.auth.user)
      setUser(props.auth.user);
    }
  }, [props]);

  const [isFirstLogin, setFirstLogin] = useState(false);
  const [bravectoDiscount, setBravectoDiscount] = useState({});
  useEffect(() => {
    if (user && user.hasOwnProperty("discounts")) {
      setStarsQty(user.score);
      setFirstLogin(user.firstLogin);
      setBravectoDiscount(user.discounts);
    }
  }, [user]);

  const [starsQty, setStarsQty] = useState(null);
  useEffect(() => {
    getStars(props.auth.user.token)
      .then((res) => {
        console.log('Resposta da API Stars', res);
        if (res && res.status === 200) {
          const resp = JSON.parse(res.request.response);
          setStarsQty(resp.data.scoreNumber)
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [props.auth.user.token])

  const [starsContent, setStarsContent] = useState([]);
  useEffect(() => {
    if (starsQty) {
      let greyStars = 5 - starsQty,
        starsHtml = [];

      for (let i = 0; i < starsQty; i++) {
        starsHtml.push(
          i + 1 === starsQty ? (
            <div id="red-star" key={`red${i}`}>
              <span>{starsQty}</span>
            </div>
          ) : (
            <div id="red-star" key={`red${i}`}></div>
          )
        );
      }

      for (let i = 0; i < greyStars; i++) {
        starsHtml.push(<span id="grey-star" key={`grey${i}`}></span>);
      }
      if (starsQty === 1) {
        document.getElementById("expiration").style.display = "none";
      }
      console.log(starsQty);
      setStarsContent(starsHtml);
    }
  }, [starsQty]);

  return (
    <div id="MeuPerfil">
      <div id="skip">
        <a href="#MeuPerfil__container">
          Pular para o conteúdo principal da página
        </a>
      </div>
      <h1 className="sr-only">
        Clube de Vantagens Bravecto: Tela inicial do usuário
      </h1>
      <Header />
      {isFirstLogin && (
        <div id="firstLogin">
          <div id="firstLogin__container">
            <FaRegCheckCircle />
            <div id="firstLogin__text">
              <span id="firstLogin__text--strong">Bem-vindo!</span>
              <span id="firstLogin__text--normal">
                {" "}
                seu cadastro <br id="noDesktop" /> foi efetuado com sucesso!
                <br />
              </span>
              <br />
              {/* <span id="firstLogin__text--little">
                Cadastre seu pet e tenha acesso aos benefícios do clube
              </span> */}
            </div>
            {/* <Link to="/cadastrar-pet">
              <button type="button">Cadastre seu pet</button>
            </Link> */}
          </div>
        </div>
      )}

      <div id="MeuPerfil__container">
        <div className="banner">
          <Link to="/editar-meu-perfil">
            <img className="bnr-desk" src={bannerDesk} alt="" />
          </Link>
          <Link to="/editar-meu-perfil">
            <img className="bnr-mob" src={bannerMobile} alt="" />
          </Link>
        </div>
        <div id="title">
          <span>Seu saldo de estrelas</span>
        </div>
        <div id="stars">
          <div id="stars__container">
            {starsContent.length > 0 && starsContent}
          </div>
        </div>
        <div id="expiration">
          <span>Válido até {expirationDate}</span>
        </div>
        {/* <div id="info">
                    <div id="info__container">
                        <span>Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet</span> 
                    </div>
                </div> */}
        <div id="buttonsTop">
          <div id="left">
            <Link to="/cadastrar-nota">
              <button type="button">Cadastrar Nota Fiscal</button>
            </Link>
          </div>
          <div id="right">
            <Link to="/meus-beneficios">
              <button type="button">Resgatar benefícios</button>
            </Link>
          </div>
        </div>
        {/* <div id="pets">
                    <div id="title">
                        <span>Meus Pets</span>
                    </div>
                    {
                        isMobile ? <Pets /> : <Pets isChild={true} />
                    }
                </div> */}
        <MeusPets isMobile={isMobile} isChild={true} />
        {/* Object.keys(bravectoDiscount).length > 0
                        ? isMobile 
                                ? <MobileDescontoBravecto 
                                    handleSubmitPromotionalCode={handleSubmitPromotionalCode}
                                    bravectoDiscount={bravectoDiscount}
                                /> 
                                : <DesktopDescontoBravecto 
                                    handleSubmitPromotionalCode={handleSubmitPromotionalCode}
                                    bravectoDiscount={bravectoDiscount}
                                />
                        : <Loader />
                    */}
        {/*
                    Object.keys(bravectoDiscount).length > 0 
                        ?   isMobile   
                                ? <MobileDescontoBanhoTosa 
                                    bravectoDiscount={bravectoDiscount}
                                />
                                : <DesktopDescontoBanhoTosa 
                                    bravectoDiscount={bravectoDiscount}
                                />
                        : <Loader />
                    */}
        <Beneficios isChild={true} />
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth, helper }) => {
  return {
    auth,
    helper,
  };
};

export default Connect(mapStateToProps)(ClubeDeVantagens);
